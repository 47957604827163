html, body{
    overflow-x: hidden !important;
}
a{
    text-decoration: none !important;
}
p,h1,h2,h3,a,span,a,li,div{
    font-family: 'Montserrat', sans-serif !important;
}
.sidebar {
    height: 100vh;
    background-image: url("../Assets/sidebarbg.png");
    background-size: cover;
    background-repeat: no-repeat;
    
}
.container-fluid{
    padding:0 !important;
}
.logo{
    text-align: center;
    padding-top: 10%;
}
.sidebar ul{
    padding-top: 15%;
}
.sidebar ul li{
    list-style: none;
}
.sidebar li a{
    display: block;
    color: #fff;
    list-style: none;
    padding: 11px 30px;
    background: transparent;
    margin-right:50px ;
    border-radius: 20px;
    margin-bottom: 20px;
    font-size: 22px;
    width: 85%;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    text-transform: uppercase;
}
.sidebar li a.active{
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 140%) ,linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
    transition: all 300ms ease-in-out;
    opacity: 0.9;
}

.dashboard{
    height: 100vh;
    background-image: url('../Assets/Dashbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.boxes{
    background-image: url('../Assets/Nobg.png');
    background-size: cover !important;
    background-repeat: no-repeat;
    padding: 30px 30px;
    margin-right: 8%;
    width: 40% !important;
    border-radius: 40px;
}
.boxes h3{
    font-size: 190px;
    font-weight: 700;
    text-align: center;
    color: #fff;
}
.boxes p{
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}
.hometext {
    height: 100vh;
    background-image: url('../Assets/Dashbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 15%;
    padding-bottom: 10% !IMPORTANT;
    overflow-y: scroll;
}
.col-sm-12.hometext .row {
    width: 85%;
    margin: auto;
}
.hometext h2{
    color: #000;
    font-weight: 700;
    font-size: 41px;
}
.col-sm-9, .col-sm-3{
    padding: 0 !important;
}
.uploads {
    width: 60%;
    margin-left: auto;
    background: #D8D8D8;
    padding: 10px 25px;
    border-radius: 10px;
}
.uploads h3{
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 10px;
}
.uploads button{
    background: transparent;
    box-shadow: unset;

}
button.save {
    position: relative;
    top: 25px;
    height: 45px;
    width: 100px;
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
}
.save:hover{
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;

}
.col-sm-12.texts {
    margin-top: 8% !important;
    width: 100%;
    margin: auto;
}
.col-sm-12.texts input{
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
    padding: 40px 25px;
    border-radius: 20px;
    color: #fff;
}
::placeholder{
    color: #fff !important;
    font-size: 22px;
    opacity: 0.5;
    font-family: 'Montserrat', sans-serif !important;
}
input {
    color: #fff !important;
    font-size: 22px !important;
    font-family: 'Montserrat', sans-serif !important;
}
.col-sm-12.texts {
    text-align: right;
}
.col-sm-12.texts .save{
    height: 60px !important;
    width: 150px ;
}
.col-sm-12.texts textarea{
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
    padding: 25px 25px;
    border-radius: 20px;
    color: #fff;
    height: 400px !important;
    font-size: 20px !IMPORTANT;
}
textarea {
    color: #fff !important;
    font-size: 22px !important;
    font-family: 'Montserrat', sans-serif !important;
}
.boxes.b2{
    margin-right: 0 !important;
}
.col-sm-12.dashboard .row {
    justify-content: center;
}
.sidebar a{
    color: #fff !important;
    width: 100%;
}
.login{
    background-image: url('../Assets/services.png');
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login form#basic {
    width: 30%;
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
    padding: 40px 40px;
    border-radius: 20px 150px;
    text-align: center;
    padding-top: 40px !important;
}
.login form#basic img{
    margin-bottom: 50px;
}
.login form#basic input{
    color: #000 !important;
    padding: 15px 10px;
}
.login form#basic ::placeholder{
    color: #000 !important;
}
.service input{
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
    padding: 30px 25px;
    border-radius: 20px;
    color: #fff;
}
.service .ant-space {
    display: block !important;
    position: relative;
}
.cross {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    background: transparent;
    color: #fff;
    border: unset;
}
.service  .save.service {
    width: 40%;
    color: #fff;
    height: 52px;
    background: #929292 !important;
    color: #000;
    text-transform: uppercase;
    height: 90px;
    border: unset !important;
}
.save.service:hover{
    color: #000 !important;
    border: unset !important;
}
.col-sm-6.texts.service {
    margin-top: 5%;
}
::-webkit-scrollbar{
    width: 0 !important;
}
.col-sm-12.hometext.service.aos-init.aos-animate {
    padding-top: 10%;
}
.col-sm-6.texts.service.logo {
    background-image: url("../Assets/logobg.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 40px;
    border: 5px solid #92929285;
    padding: 25px 0;
}
.col-sm-6.texts.service.logo .ant-upload.ant-upload-select{
    width: 100% !important;
}
.col-sm-6.texts.service.logo button.save.service {
    width: 100% !important;
    margin: 0 !IMPORTANT;
    position: relative;
    top: 0;
}
a.save {
    position: relative;
    top: 25px;
    height: 45px;
    width: 100px;
    font-size: 18px;
    font-weight: 600;
    padding: 20px 50px;
    color: #000;
    /* background: linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%), linear-gradient(180deg, #B8B8B8 0%, #918989 100%); */
    background: #b3b2b2;
    border: 2px solid #A5A1A1;
    border-radius: 8px;
    transition: all 300ms ease-in-out;
}
a.save:hover{
    transition: all 300ms ease-in-out;
    color: #fff !important;
}
table {
    caption-side: bottom;
    border-collapse: collapse;
    width: 100%;
}
.lists {
    background: linear-gradient(180deg, #000000 0%, #4e4d4d59 100%), linear-gradient(180deg, #8D8D8D 0%, rgb(0 0 0 / 36%) 100%);
    padding: 3% 4%;
    border-radius: 30px;
    overflow-y: scroll;
}
.lists p{
    text-transform: uppercase;
    color: #fff;
    margin: 0;
}
.lists td {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    padding: 20px 0;
}
.op{
    opacity: 0.5;
}
.comform {
    background: linear-gradient(180deg, #000000 0%, #4e4d4d59 100%), linear-gradient(180deg, #8D8D8D 0%, rgb(0 0 0 / 36%) 100%);
    /* background: #efefef; */

}
.comform input{
    background: linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%), linear-gradient(180deg, #B8B8B8 0%, #918989 100%) !important;

}
.comform .ant-upload.ant-upload-select{
    background: linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%), linear-gradient(180deg, #B8B8B8 0%, #918989 100%) !important;

}
.lists.leads th {
    text-align: left;
}
.plusupload {
    border: unset;
    height: 100px;
    width: 100%;
    border: 1px dotted #000;
    background: linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%), linear-gradient(180deg, #B8B8B8 0%, #918989 100%);
}

.comform .ant-space.ant-space-horizontal.ant-space-align-center {
    position: relative;
}
.comform .cross {
    right: 10px !important;
}
.comform input#dynamic_form_nest_item_service {
    padding: 10px;
    border: unset;
    background: transparent !important;
}
.minerow {
    width: 100% !important;
    margin: 0 !important;
}
.minerow h2 {
    color: #fff !important;
    text-align: left;
    font-size: 18px !important;
    padding-bottom: 20px;
    opacity: 0.5;
    font-weight: 500 !important;
}
.col-sm-12.texts.comform {
    padding: 0px 0;
    border-radius: 25px;
    padding-bottom: 0;
}
.videosinput .ant-space-horizontal.ant-space-align-center {
    width: 100%;
}
.videosinput .ant-space {
    display: inline-block;
}
.col-sm-12.texts .videosinput  input{
    background: linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%), linear-gradient(180deg, #B8B8B8 0%, #918989 100%) !important;
}
.col-sm-12.texts.comform .ant-btn-primary.save {
    position: absolute;
    right: 0;
    top: unset;
    bottom: -145px;
}
.col-sm-12.texts.comform {
    margin-top: 4% !important;
}
.col-sm-12.hometext.editss.aos-init.aos-animate {
    padding-top: 10%;
}
.ant-form-item-explain-error {
    text-align: left;
    font-size: 11px;
    padding-left: 20px;
}
.leads th {
    text-align: center;
    color: #fff;
    opacity: 0.7;
}
.leads td{
    text-align: center;
}
.col-sm-12.texts.lists.leads.viewleads input{
    border: unset !important;
    background: unset !important;
    border-bottom: 1px solid #929292 !important;
    border-radius: 0 !important;
    padding: 15px 25px !important;
}
.col-sm-12.texts.lists.leads.viewleads textarea{
    border: unset !important;
    background: unset !important;
    border-bottom: 1px solid #929292 !important;
    border-radius: 0 !important;
    padding: 15px 25px !important;
}
.viewsmine {
    background-image: url('../Assets/bgleads.png');
    background-repeat: no-repeat;
    background-size: cover;
}
input:focus{
    box-shadow: unset !important;
    border: unset !important;
    outline: unset !important;
}

textarea:focus{
    box-shadow: unset !important;
    border: unset !important;
    outline: unset !important;
}
.getnames{
    width: 100% !important;
    padding-top: 20px ;
}
.getnames .col-sm-12{
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
    padding: 30px 25px;
    border-radius: 20px;
    margin-bottom: 10px;
}
.getnames .col-sm-12 h2{
    color: #fff !important;
    font-size: 18px !important;
    margin: 0 !important;
}
.editrow {
    width: 90% !important;
    padding: 0px 10px;
}
.editrow input{
    padding: 0 !important;
    border: unset !important;
    background: transparent !important;
}
.getnames button{
background-color: #918989 !important;
color: #fff !important;
}
ul.ant-pagination {
    text-align: center;
    margin-top: 20px;
}
.ant-input-disabled{
    cursor: pointer !important;
}
.lists.leads ul.ant-pagination li:first-child {
    color: #fff;
}
.lists.leads button{
    color: #fff !important;
}
.lists.leads ul.ant-pagination  a {
    background: #fff;
    border-radius: 10px;
}
.lists.leads td img{
cursor: pointer;

}
.row.minerow .col-sm-2 {
    width: 100%;
}
.row.minerow .col-sm-2 .ant-upload-list-item-container {
    width: 100% !important;
}
.row.minerow .col-sm-2 .ant-upload-list-item-container img.ant-upload-list-item-image {
    width: 100% !important;
    object-fit: cover !important;
}
.row.minerow .col-sm-2 .ant-upload.ant-upload-select {
    width: 100% !important;
}
.row.minerow .col-sm-12 {
    text-align: left !important;
}
.row.minerow .col-sm-12  img.ant-upload-list-item-image {
    width: 100% !important;
    object-fit: cover !important;
}
.service.logo .row.getnames .col-sm-12 {
    background: transparent !important;
    width: 25%;
    padding-left: 0 !important;
}
.service.logo  .row.editrow .col-sm-6 {
    width: 100%;
    padding-right: 0;
}
.service.logo  .row.editrow .col-sm-6 button{
    width: 100% !important;
    margin-top: 10px;
    border: unset ;
}
.service.logo  .row.editrow {
    width: 100% !important;
}
.ant-upload-list-item-container {
    width: 100% !important;
}
.service.logo img.ant-upload-list-item-image {
    width: 100% !important;
    object-fit: cover !important;
}
.row.getnames button {
    font-size: 14px;
    border-radius: 8px;
}
.mobile{
    display: none;
}
.desktop{
    display: block;
}
.row.editrow .col-sm-6 .save.service {
    height: 25px !important;
    text-transform: capitalize !important;
    background: #918989 !important;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}
.save.service:hover{
    color: #fff !important;
}
.row.editrow .col-sm-6 #dynamic_form_nest_item .ant-upload.ant-upload-select {
    height: 25px !important;
    margin-top: 10px !important;
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    background: #918989;
    border: unset;
}
.row.editrow .col-sm-6 #dynamic_form_nest_item .col-sm-6{
    padding-left: 0 !important;
}
.row.editrow .col-sm-6 #dynamic_form_nest_item .ant-upload.ant-upload-select div{
    color: #fff !important;
    font-weight: 400;
}
.row.editrow .col-sm-6 #dynamic_form_nest_item .ant-upload.ant-upload-select span.anticon.anticon-plus {
    display: none;
}
.row.editrow .col-sm-6 #dynamic_form_nest_item  .ant-form-item {
    margin: 0;
    margin-bottom: 5px;
}
.row.editrow .col-sm-6 #dynamic_form_nest_item .ant-btn-icon-only.ant-upload-list-item-action {
    width: 25px !important;
    background: transparent !important;
}
.row.editrow .col-sm-6 #dynamic_form_nest_item  .ant-upload-list-item.ant-upload-list-item-undefined {
    padding: 0 !important;
    border-radius: 10px !important;
}
.row.editrow .col-sm-6 #dynamic_form_nest_item  .ant-upload-list-item.ant-upload-list-item-undefined img{
    height: 150px !important;
    border-radius: 10px !important;
}
.minerow .ant-upload-list-item-container {
    width: 10% !important;
}
.col-sm-3.side {
    width: 20%;
}
.col-sm-9.main{
    width: 80%;
}
.lists.leads {
    height: 550px;
    overflow-y: scroll;
}
.lists.leads img {
    width: 30%;
}
.lists.leads.myleads img {
    width: 20%;
}
.col-sm-9.side {
    width: 80%;
}
.col-sm-12.texts td {
    text-align: left;
    padding-bottom: 25px;
    padding-top: 25px;
}
.col-sm-12.texts tr {
 border-bottom: 1px solid #efefef;

}
.ant-image .ant-image-img {
    object-fit: contain;
}
.col-sm-12.texts.lists.leads.viewleads {
    width: 90%;
}
button.logout {
    position: absolute;
    bottom: 0;
    right: 0;
}
.sidebar{
    position: relative;
}
button.logout {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 60px;
    width: 40%;
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
    font-size: 18px;
    font-weight: 600;
    border-radius: 0;
    color: #fff;
    border: unset;
}
.ant-input-affix-wrapper {
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
    border-radius: 20px;
    
}
.ant-input-affix-wrapper .anticon.ant-input-password-icon svg {
    color: #ffff !important;
}
.editProfileForm .ant-row.ant-form-item-row {
    display: block;
    text-align: left;
}
.editProfileForm .ant-form-item .ant-form-item-control-input-content {
 
    text-align: end;
}
.deleteModal.ant-modal .ant-modal-content {
    background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
color: #ffff;
}
.deleteModal button.ant-btn.css-dev-only-do-not-override-190m0jy.ant-btn-primary {
    
      
     
        font-weight: 600;
      
        color: #000;
        background: #b3b2b2;
        border: 2px solid #A5A1A1;
        border-radius: 8px;
        transition: all 300ms ease-in-out;
    
}
.deleteModal button.ant-btn.css-dev-only-do-not-override-190m0jy.ant-btn-primary:hover{
color: #fff;


}
.deleteModal .anticon svg {
    display: inline-block;
    color: #ffff;
}

.ant-row.ant-form-item-row {
    display: block;
}