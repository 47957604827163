@media only screen and (max-width: 1550px) {
    .sidebar li a {
        display: block;
        color: #fff;
        list-style: none;
        padding: 15px 30px !important;
        background: transparent;
        margin-right: 50px;
        border-radius: 20px;
        margin-bottom: 10px;
        font-size: 16px !important;
        width: 92%;
        cursor: pointer;
        transition: all 300ms ease-in-out;
        text-transform: uppercase;
    }
    .service .save.service {
        height: 60px !important;
    }
    .hometext h2 {
        font-size: 32px !important;
    }
    .row.minerow h2 {
        font-size: 18px !important;
    }
    ::placeholder{
        font-size: 18px !important;
    }
    input{
        font-size: 18px !important;
    }
    textarea{
        font-size: 18px !important;
    }
    .boxes h3 {
        font-size: 100px;
        font-weight: 700;
        text-align: center;
        color: #fff;
    }
    .boxes p {
        font-size: 18px !important;
    }
    .sidebar ul {
        padding-top: 10%;
    }
    .col-sm-12.texts textarea {
        font-size: 18px !IMPORTANT;
    }
     textarea {
        font-size: 18px !IMPORTANT;
    }
    .hometext {
        padding-top: 10%;
        padding-bottom: 4% !IMPORTANT;
    }
    .service.logo .row.getnames .col-sm-12 {
        background: transparent !important;
        width: 33%;
        padding-left: 0 !important;
        padding-bottom: 0;
        padding-right: 0;
    }
    .row.getnames button {
        font-size: 12px;
        border-radius: 8px;
    }
    .row.getnames .ant-image {
        width: 130px !important;
    }
    .lists.leads {
        height: 400px;
        overflow-y: scroll;
    }
    .lists.leads td img{
        width: 30% !important;
    }
    .lists.leads.myleads img {
        width: 20% !important;
    }
}
@media only screen and (max-width: 1450px) {
    .col-sm-12.texts textarea {
        font-size: 18px !IMPORTANT;
    }
    .row.getnames .ant-image {
        width: 120px !important;
    }
    a.save {
        padding: 15px 40px;
    }
    .sidebar li a {
        font-size: 14px !important;
    }
}
@media only screen and (max-width: 1370px) {
    .sidebar li a {
        padding: 17px 30px !important;
        font-size: 16px !important;
    }
    ::placeholder{
        font-size: 16px !important;
    }
    input{
        font-size: 16px !important;
    }
    textarea{
        font-size: 16px !important;
    }
    .col-sm-12.texts {
        margin-top: 8% !important;
        width: 100%;
        margin: auto;
    }
    .row.getnames .ant-image {
        width: 110px !important;
    }
    a.save {
        padding: 15px 35px;
    }
    .logo img {
        width: 20%;
    }
    .sidebar li a {
        font-size: 14px !important;
    }
}
@media only screen and (max-width: 480px) {
    .desktop{
        display: none !important;
    }
    .mobile{
        display: block !important;
    }
    .offcanvas-body {
        padding: 0 !important;
    }
    .offcanvas-header {
        padding: 0 !important;
        position: absolute;
        right: 0;
        z-index: 99999999;
    }
    button.btn-close {
        color: #000 !important;
        background: #fff !important;
        opacity: 1;
        width: 50px;
        padding: 20px 10px;
        height: 30px;
    }
    button.btn-close::before{
        content: "Close";
        font-size: 14px;
        font-weight: bold;
    }
    button.btn.btn-primary {
        background: url("../Assets/Nobg.png") !important;
        border: unset !important;
        padding: 10px 35px;
        font-weight: 700;
        border-radius: 0px 27px 10px 0px;
        background-size: cover !important;
    }
    .boxes {
        background-size: cover !important;
        background-repeat: no-repeat;
        padding: 30px 30px;
        margin-right: 0;
        width: 80% !important;
        border-radius: 40px;
        margin-bottom: 5%;
    }
    .mobile {
        position: fixed;
        top: 5%;
        z-index: 999;
    }
    .hometext {
        padding-top: 30%;
        padding-bottom: 4% !IMPORTANT;
    }
    .col-sm-12.hometext .row {
        width: 100%;
        margin: auto;
        padding: 0 10px;
    }
    .col-sm-12.texts {
        margin-top: 8% !important;
        width: 95%;
        margin: auto;
    }
    .col-sm-12.texts input {
        padding: 20px 25px;
    }
    .col-sm-12.texts textarea {
        font-size: 16px !IMPORTANT;
    }
    .col-sm-12.hometext.service.aos-init.aos-animate {
        padding-top: 30%;
    }
    .service .save.service {
        width: 100%;
        margin-bottom: 20px !important;
    }
    .service input {
        padding: 20px 25px !important;
    }
    .service .ant-form-item {
        margin-bottom: 0 !IMPORTANT;
    }
    .row.getnames {
        margin-top: 20px !IMPORTANT;
    }
    .getnames .col-sm-12 {
        padding: 10px 0px !important;
    }
    .getnames .col-sm-12 .col-sm-8{
        width: 60% !important;
        padding:0 !important;
    }
    .getnames .col-sm-12 .col-sm-8 h2{
        font-size: 13px !important;
        font-weight: 500 !important;
    }
    .getnames .col-sm-12 .col-sm-2{
        width: 20% !important;
    }
    .getnames .col-sm-12 .row{
        align-items: center;
    }
    .row.getnames .ant-image {
        width: 75px !important;
    }
    .ant-image-mask-info {
        font-size: 12px !IMPORTANT;
    }
    .row.getnames .row.getnames {
        padding: 0;
    }
    .lists td {
        text-transform: uppercase;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        padding: 20px 40px !important;
        padding-left: 15px !important;
    }
    .lists.leads {
        width: 100%;
        overflow-x: scroll;
    }
    .lists.leads table{
        width: 1000px;
    }
    .lists.leads {
        margin-top: 35px;
    }
    .col-sm-12.hometext.editss.aos-init.aos-animate {
        padding-top: 30%;
    }
    .leads th {
        text-align: left;
        color: #fff;
        opacity: 0.7;
        padding-left: 12px;
    }
    .col-sm-9.viewsmine.aos-init.aos-animate {
        margin-top: 20% !important;
        width: 90%;
        margin: auto;
    }
    .col-sm-12.sidebar ul {
        padding-left: 10px !important;
    }
    .row.editrow .col-sm-6 #dynamic_form_nest_item .ant-upload.ant-upload-select div {
        color: #fff !important;
        font-weight: 400;
        font-size: 12px;
    }
    .col-sm-9.main {
        width: 100%;
    }
    .login form#basic {
        width: 95% !important;
        background: linear-gradient(180deg, #000000 0%, #4E4D4D 100%),linear-gradient(180deg, #8D8D8D 0%, rgba(0, 0, 0, 0.52) 100%) !important;
        padding: 40px 20px;
        border-radius: 20px 150px;
        text-align: center;
        padding-top: 25px !important;
    }
    .login form#basic img {
        margin-bottom: 25px;
        width: 20%;
    }
    .col-sm-9.side {
        width: 100%;
    }
}